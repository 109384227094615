var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"40vw","scrim":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-center font-weight-bold"},[_c('v-icon',{attrs:{"left":"","large":"","color":"green"}},[_vm._v("mdi-view-dashboard")]),_c('h2',[_vm._v("Add activity")])],1),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"icon":"","x-large":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('div',{staticClass:"pl-4 pb-4"},[_c('v-btn',{attrs:{"color":"primary","small":"","dark":"","rounded":"","outlined":""},on:{"click":_vm.openBuilder}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Make activity ")],1)],1),_c('v-container',[_c('v-row',_vm._l((_vm.activities),function(activity){return _c('v-col',{key:activity.name,attrs:{"cols":"12"}},[[_c('v-card',{staticClass:"d-flex flex-wrap my-card pa-6",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{staticStyle:{"width":"300px"},attrs:{"src":activity.activityImage}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',{},[_c('v-col',[_c('div',{staticClass:"title__activityName"},[_vm._v(_vm._s(activity.name))])])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"description"},[_vm._v(_vm._s(activity.description))])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"btn font-weight-bold",attrs:{"rounded":"","depressed":"","large":"","color":"primary","disabled":_vm.programDoc &&
                            _vm.programDoc.data &&
                            !!_vm.programDoc.data.adks.find(function (adk) { return adk.name === activity.name; })},on:{"click":function($event){(_vm.isCategoryChoices = true), (_vm.selectedAdkItem = activity)}}},[_vm._v("Add")])],1)],1)],1)],1)],1)]],2)}),1)],1),_c('v-dialog',{attrs:{"value":_vm.isCategoryChoices,"width":"700","persistent":""}},[_c('v-card',{staticStyle:{"overflow":"hidden !important","padding":"20px"}},[(
              _vm.selectedAdkItem.isTeamAdk &&
              _vm.selectedAdkItem.name &&
              _vm.selectedAdkItem.name.toString() !== 'team'
            )?_c('v-card-title',{staticClass:"d-flex"},[_c('div',{staticStyle:{"max-width":"560px"}},[_c('p',{staticStyle:{"word-break":"break-word","white-space":"pre-line","font-size":"medium"}},[_vm._v(" Please make sure you have added Team ADK inside your program or template before `"),_c('b',[_vm._v(_vm._s(_vm.computedSelectedAdkName))]),_vm._v("` ADK in order to make `"),_c('b',[_vm._v(_vm._s(_vm.computedSelectedAdkName))]),_vm._v("` ADK function well in your program. ")])]),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"d-flex text-end",attrs:{"position":"absolute","icon":"","x-large":""},on:{"click":function($event){_vm.isCategoryChoices = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]):_c('div',{staticClass:"select-category-close"},[_c('v-btn',{staticClass:"d-flex text-end",attrs:{"position":"absolute","icon":"","x-large":""},on:{"click":function($event){_vm.isCategoryChoices = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [_c('v-row',{staticStyle:{}},[_c('v-col',[_c('v-responsive',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-3",attrs:{"outlined":true,"items":_vm.transformedItems,"rounded":true,"item-text":"displayText","item-value":"value","error-messages":errors,"label":"Select category","required":""},model:{value:(_vm.currentCategory),callback:function ($$v) {_vm.currentCategory=$$v},expression:"currentCategory"}})]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-divider'),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3",attrs:{"dark":true,"rounded":true,"x-large":true,"disabled":invalid,"loading":_vm.isAdding},on:{"click":function($event){return _vm.selectAdk(_vm.selectedAdkItem)}}},[_vm._v("Add")])],1)],1)]}}])})],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }