






















































































































































import { computed, defineComponent, ref, toRef } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { useUserActions } from '@/store';

const { fetchActivities, addNewAdk } = useUserActions(['fetchActivities', 'addNewAdk']);

export default defineComponent({
  name: 'MActivitySchema',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    programDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    schemaIndex: {
      required: false,
      type: [Number, String],
      default: () => ''
    },
    isTemplate: {
      required: false,
      type: Boolean
    },
    isDraft: {
      required: false,
      type: Boolean
    },
    category: {
      required: false,
      type: String
    }
  },
  setup(props: any, ctx: any) {
    const schemaIndex = computed(() => props.schemaIndex);
    const isCategoryChoices = ref(false);
    const selectedAdkItem = ref({});
    const isAdding = ref(false);
    const dialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const programDocValue = computed({
      get: () => props.programDoc,
      set: newPage => {
        ctx.emit('input', newPage);
      }
    });

    const categories = computed(() => {
      return programDocValue.value?.data?.categories;
    });

    const categoryNames = computed(() => {
      return categories?.value?.map(category => category.name);
    });

    const transformedItems = computed(() => {
      return categoryNames.value.map(item => ({
        displayText: item.charAt(0).toUpperCase() + item.slice(1),
        value: item
      }));
    });

    const computedSelectedAdkName = computed(() => {
      return (
        selectedAdkItem?.value?.name?.charAt(0).toUpperCase() +
        selectedAdkItem?.value?.name?.slice(1)
      );
    });

    const currentCategory = ref(props.category);

    const openBuilder = () => {
      const id = programDocValue?.value?.data?._id?.toString();
      let schemaType = props.isTemplate ? 'template' : 'program';
      if (props?.isDraft) {
        schemaType = 'ProgramRevision';
      }
      localStorage.setItem('redirectionPath', ctx.root.$route.path);
      if (schemaIndex.value >= 1 && schemaIndex.value !== '') {
        ctx.root.$router.push({
          name: 'AddAdk',
          params: {
            id,
            schemaType
          },
          query: {
            index: schemaIndex.value,
            ...(currentCategory.value && {
              category: currentCategory.value
            })
          }
        });
      } else {
        ctx.root.$router.push({
          name: 'AddAdk',
          params: {
            schemaType,
            id,
            ...(currentCategory.value && {
              category: currentCategory.value
            })
          }
        });
      }
    };
    const activities = ref(false);

    const getActivities = async () => {
      const response = await fetchActivities();
      activities.value = response.sort((a, b) => a.position - b.position);
    };

    getActivities();

    const selectAdk = async activity => {
      isAdding.value = true;
      const adkValue = activity;
      delete adkValue._id;
      let isCategoryMatch = true;
      adkValue.category = currentCategory.value;
      if (props.category !== currentCategory.value) {
        isCategoryMatch = false;
      }
      let document_type = 'Program';
      if (props?.isTemplate) {
        document_type = 'ProgramSchema';
      } else if (props?.isDraft) {
        document_type = 'ProgramRevision';
      }
      if (!props?.isTemplate && adkValue?.fields?.length >= 1) {
        adkValue.fields = adkValue?.fields?.map(field => {
          if (field?.id) {
            return { ...field, id: new ObjectId(field?.id) };
          }
          return { ...field, id: new ObjectId() };
        });
      }
      if (!props?.isTemplate && adkValue?.presetFields?.length >= 1) {
        adkValue.presetFields = adkValue?.presetFields?.map(field => {
          if (field?.id) {
            return { ...field, id: new ObjectId(field?.id) };
          }
          return { ...field, id: new ObjectId() };
        });
      }
      adkValue.id = new ObjectId();
      const response = await addNewAdk({
        programOrTemplateId: programDocValue.value.data._id,
        adk_name: activity.name,
        data: adkValue,
        document_type,
        isCatMatch: isCategoryMatch,
        ...(schemaIndex.value >= 0 && { index: schemaIndex.value })
      });
      if (response) {
        isAdding.value = false;
        dialog.value = false;
        props.fetchProgram();
      }
    };

    return {
      openBuilder,
      dialog,
      activities,
      selectAdk,
      isCategoryChoices,
      selectedAdkItem,
      transformedItems,
      currentCategory,
      isAdding,
      computedSelectedAdkName
    };
  }
});
